<template>
  <v-container fluid>
    <div>
      <v-row v-if="successExe" dense no-gutters class="px-0">
        <v-col v-if="successExe" cols="12">
          <v-alert color="success" :data-cy="`${successExe} ${successEventId}`">
            {{ successExe }} <span
              class="clickable-simple"
              @click="copyEventId()"
            >{{ successEventId.eventId }}</span><v-icon class="clickable-simple" @click="copyEventId()">mdi-vector-combine</v-icon>
            &nbsp;&nbsp;
            <span>
              <v-btn
                outlined
                class="button-default-width"
                @click="goToEventLogs()"
              >
                {{ $lang.labels.events }}
              </v-btn>
            </span>
            &nbsp;&nbsp;
            <span>
              <v-btn
                outlined
                class="button-default-width"
                @click="goToStepLogs()"
              >
                {{ $lang.labels.steps }}
              </v-btn>
            </span>
            &nbsp;&nbsp;
            <span>
              <v-btn
                outlined
                class="button-default-width"
                @click="goToProcessDebug()"
              >
                {{ $lang.labels.debug }}
              </v-btn>
            </span>
          </v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters>
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
            <v-tab
              :key="3"
              data-cy="tab-comment"
            >
              {{ $lang.labels.comment }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="cron.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          class="required-asterisk copy-name-icon mb-3"
                          :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                          data-cy="cron-name"
                          append-icon="mdi-vector-combine"
                          @click:append="copyName()"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                      <v-btn
                        class="button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        {{ cron.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters class="pb-4">
                    <v-col
                      cols="9"
                      class="pr-sm-1"
                    >
                      <v-autocomplete
                        v-model="cron.processId"
                        outlined
                        dense
                        :items="processes"
                        :loading="isLoadingProcesses"
                        :search-input.sync="searchProcesses"
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        :label="$lang.labels.process"
                        :placeholder="$lang.actions.startTyping"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        required
                        clearable
                        class="required-asterisk"
                        :readonly="!userCanEdit"
                        data-cy="cron-processId"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item.id">{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': cron.processId, 'color-secondary': !cron.processId}"
                        @click="cron.processId ? openProcess() : ''"
                      >
                        {{ $lang.actions.openProcess }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-combobox
                        v-model="cron.instanceIds"
                        outlined
                        dense
                        multiple
                        chips
                        :items="instances"
                        :label="$lang.labels.instances"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        clearable
                        :readonly="!userCanEdit"
                        data-cy="cron-instanceIds"
                        :rules="[v => v.length < 49 || 'Max 48 instances']"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item">{{ item }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="seconds"
                        :items="formattedSeconds"
                        :label="$lang.labels.seconds"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                        data-cy="cron-seconds"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} sec`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pl-sm-1"
                    >
                      <v-select
                        v-model="secondsInterval"
                        :items="formattedMinutesInterval"
                        :label="$lang.labels.secondsInterval"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-secondsInterval"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} sec int`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="minutes"
                        :items="formattedSeconds"
                        :label="$lang.labels.minutes"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-minutes"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} min`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pl-sm-1"
                    >
                      <v-select
                        v-model="minutesInterval"
                        :items="formattedMinutesInterval"
                        :label="$lang.labels.minutesInterval"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-minutesInterval"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} min int`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="hours"
                        :items="formattedHours"
                        :label="$lang.labels.hours"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-hours"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} hr`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="pl-sm-1"
                    >
                      <v-select
                        v-model="hoursInterval"
                        :items="formattedHoursInterval"
                        :label="$lang.labels.hoursInterval"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-hoursInterval"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} hr int`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="dayOfMonth"
                        :items="formattedDaysOfMonth"
                        :label="$lang.labels.dayOfMonth"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-dayOfMonth"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} day month`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="month"
                        :items="formattedMonths"
                        :label="$lang.labels.month"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-month"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} month`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="dayOfWeek"
                        :items="formattedDays"
                        :label="$lang.labels.dayOfWeek"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-dayOfWeek"
                        class="required-asterisk"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item.value} day week`">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="cron.priority"
                        :items="priorities"
                        :label="$lang.labels.priority"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-priority"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item} priority`">{{ item }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-checkbox
                        v-model="cron.isGdprRelevant"
                        :label="$lang.labels.gdpr"
                        data-cy="cron-isGdprRelevant"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          color="secondary"
                          class="button-default-width"
                          :to="{ name: 'cron', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="cron.name"
                          :title="$lang.actions.delete"
                          type="cron"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'cron-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteCronFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'cron' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-inline-flex">
                        <v-btn
                          :disabled="!valid || lock || !userCanEdit || !isEdit"
                          color="accent"
                          class="mr-2 button-default-width color-accent-text"
                          @click="copyCreateResource(cron, 'cronCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                        >
                          {{ $lang.actions.copyCreate }}
                        </v-btn>
                        <v-btn
                          :disabled="!isEdit || lock || !userCanEdit"
                          color="accent"
                          class="button-default-width mr-2 color-accent-text"
                          data-cy="cron-execute"
                          @click="execute()"
                        >
                          <v-icon
                            left
                            dark
                            class="mr-1"
                          >
                            mdi-play
                          </v-icon>
                          {{ $lang.actions.execute }}
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="showCreateProcessForTriggerButton"
                          :action-text="$lang.actions.triggerCreateProcess"
                          :title="$lang.actions.createProcess"
                          :is-disabled="!isUserProcessCreator"
                          :button-text="$lang.actions.submit"
                          :button-color="'success'"
                          :data-cy="'rest-create-process'"
                          :simple-error="err"
                          :btn-icon="'mdi-floppy'"
                          class="ml-2"
                          @submit="createProcessForTrigger()"
                          @closeDialog="() => err = $lang.errors.processRequired"
                        />
                        <v-btn
                          v-else
                          :disabled="!valid || lock || !userCanEdit"
                          color="success"
                          class="button-default-width"
                          data-cy="cron-submit"
                          @click="submit()"
                        >
                          <v-icon
                            left
                            dark
                            class="mr-1"
                          >
                            mdi mdi-floppy
                          </v-icon>
                          {{ $lang.actions.submit }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class=""
            >
              <v-card class="py-1" flat>
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreCron($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="3"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col
                    cols="12"
                  >
                    <div class="d-flex justify-space-between">
                      <p class="pa-0 ma-0">{{ $lang.labels.comment }}</p>
                      <p
                        class="pa-0 ma-0 clickable-simple color-primary"
                        data-cy="edit-comment"
                        @click="lock || !userCanEdit ? '' : showMarkdown = true"
                      >
                        {{ $lang.actions.edit }}
                      </p>
                    </div>
                    <div class="mark-class pa-1 mb-2 mt-1 clickable-simple" data-cy="cron-comment" @click="lock || !userCanEdit ? '' : showMarkdown = true" v-html="compiledMarkdown"></div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="showMarkdown" v-model="showMarkdown" max-width="80%" width="80%">
        <markdown-modal
          :markdown-data="cron.comment"
          @save="cron.comment = $event"
          @closeDialog="showMarkdown = false"
        ></markdown-modal>
      </v-dialog>
    </div>
  </v-container >
</template>

<script>
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import MarkdownModal from '@/components/ui/modals/MarkdownModal'
import {
  disableTriggerCronUsingGET as disableCron,
  enableTriggerCronUsingGET as enableCron,
  getTriggerCronByIdUsingGET as getCron,
  updateTriggerCronUsingPUT as updateCron,
  createTriggerCronUsingPOST as createCron,
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess,
  executeUsingGET as executeCron,
  getHistoriesUsingGET as getHistories,
  deleteCronTriggerUsingDELETE as deleteCron,
  getInstancesUsingGET as getInstances
} from '@/utils/api'
import {
  doesProcessNameExist,
  createProcessForTriggerData
} from '@/utils/helpers'
import copy from 'copy-to-clipboard'
import { marked } from 'marked'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import { mapActions } from 'vuex'
import { copyCreateResource, getRolesWithoutAuth } from '@/utils/helpers'

export default {
  components: {
    ActionButtonWithConfirmation,
    Table,
    MarkdownModal,
    UserRolesSelect
  },
  data() {
    return {
      showMarkdown: false,
      tab: 0,
      err: '',
      success: '',
      successExe: '',
      successEventId: '',
      valid: false,
      isEdit: false,
      loading: true,
      isLoadingProcesses: false,
      lock: false,
      cron: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        comment: '',
        processId: '',
        status: '',
        triggerCondition: '',
        isGdprRelevant: false,
        roles: [],
        priority: 64,
        instanceIds: []
      },
      processes: [],
      searchProcesses: '',
      seconds: 0,
      secondsInterval: null,
      minutes: 0,
      minutesInterval: null,
      hours: '*',
      hoursInterval: null,
      dayOfMonth: '*',
      month: '*',
      dayOfWeek: '*',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      instances: [],
      isUserProcessCreator: false,
      showCreateProcessDialog: false
    }
  },
  computed: {
    priorities() {
      return Array.from({ length: 128 }, (_, index) => index + 1)
    },
    compiledMarkdown: function() {
      return marked.parse(this.cron?.comment) || ''
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formattedDaysOfMonth() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 1; i < 32; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedDays() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 1; i < 8; i++) {
        arr.push({
          text: this.$lang.days[i],
          value: i
        })
      }

      return arr
    },
    formattedMonths() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 1; i < 12; i++) {
        arr.push({
          text: this.$lang.months[i],
          value: i
        })
      }

      return arr
    },
    formattedHoursInterval() {
      const arr = [{
        text: 'None',
        value: null
      }]

      for (let i = 1; i < 13; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedHours() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 0; i < 24; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedMinutesInterval() {
      const arr = [{
        text: 'None',
        value: null
      }]

      for (let i = 1; i < 31; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formattedSeconds() {
      const arr = [{
        text: '*',
        value: '*'
      }]

      for (let i = 0; i < 60; i++) {
        arr.push({
          text: i,
          value: i
        })
      }

      return arr
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.cron.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.cron.modifiedOn)}, ID: ${this.cron.id}`
    },
    showCreateProcessForTriggerButton() {
      return this.isUserProcessCreator && !this.cron.processId
    },
    cronRoles() {
      return [
        ...this.editRolesIds.map((x) => ({ permissionType: 'EDIT', roleId: x })),
        ...this.useRolesIds.map((x) => ({ permissionType: 'USE', roleId: x })),
        ...this.viewRolesIds.map((x) => ({ permissionType: 'VIEW', roleId: x }))
      ]
    }
  },
  watch: {
    tab(val) {
      if (val === 2) this.fetchHistory()
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    },
    hoursInterval: {
      handler(val) {
        if (val) this.hours = '*'
      }
    },
    minutesInterval: {
      handler(val) {
        if (val) this.minutes = '*'
      }
    },
    secondsInterval: {
      handler(val) {
        if (val) this.seconds = '*'
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'cronEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'TRIGGER_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        this.isUserProcessCreator = !!user.roles.find((x) => x.name === 'PROCESS_CREATOR')
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getCron({ id: this.$route.params.id })
            .then((res) => {
              this.cron = res.data.data

              this.editRolesIds = this.cron.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.cron.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.cron.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (this.cron.comment === null) {
                this.cron.comment = ''
              }

              if (this.cron.priority === null || this.cron.priority === undefined) {
                this.cron.priority = 64
              }

              this.deconstructTriggerCondition()

              getProcess({ id: res.data.data.processId })
                .then((res) => {
                  this.processes = [res.data.data]

                  this.loading = false
                })
                .catch((err) => {
                  this.addSnackbar({
                    message: err,
                    timeout: 5000,
                    color: 'error'
                  })                })

            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else if (this.$route.params.restore) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.cron = this.$route.params.restore
          if (this.cron.comment === null) {
            this.cron.comment = ''
          }

          if (this.cron.priority === null || this.cron.priority === undefined) {
            this.cron.priority = 64
          }

          this.deconstructTriggerCondition()

          getProcess({ id: this.cron.processId })
            .then((res) => {
              this.processes = [res.data.data]

              this.loading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.cron = this.$route.params.copy
            if (this.cron.comment === null) {
              this.cron.comment = ''
            }

            if (this.cron.priority === null || this.cron.priority === undefined) {
              this.cron.priority = 64
            }

            this.deconstructTriggerCondition()

            getProcess({ id: this.cron.processId })
              .then((res) => {
                this.processes = [res.data.data]

                this.loading = false
              })
              .catch((err) => {
                this.addSnackbar({
                  message: err,
                  timeout: 5000,
                  color: 'error'
                })              })
          } else {
            this.loading = false
          }
        }
      })
  },
  methods: {
    copyCreateResource,
    ...mapActions('app', ['addSnackbar']),
    copyName() {
      if (this.cron.name) {
        copy(this.cron.name)

        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })

      } else {

        this.addSnackbar({
          message: this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })

      }
    },
    deleteCronFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteCron({ id: this.cron.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    openProcess() {
      if (this.cron.processId) window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.cron.processId}`, '_blank')
    },
    fetchHistory(options) {
      if (!this.cron.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.cron.id
      obj.resourceType = 'TRIGGER_CRON'

      getHistories(obj)
        .then((res) => {
          if (res.status !== 200) {

            this.addSnackbar({
              message: this.$lang.errors.historyFetch,
              timeout: 5000,
              color: 'error'
            })

            return
          }

          this.history = res.data.data
        })
        .catch((err) => {

          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    restoreCron(data) {
      this.cron = data

      if (!this.cron.comment) this.cron.comment = ''

      if (this.cron && this.cron.roles) {
        this.editRolesIds = this.cron.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.cron.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.cron.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }

      this.deconstructTriggerCondition()

      getProcess({ id: this.cron.processId })
        .then((res) => {
          this.processes = [res.data.data]

          this.loading = false
        })
        .catch((err) => {

          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    async init() {
      const instances = await getInstances()

      this.instances = instances.data.data.items.filter((y) => y.type === 'PROCESSING').map((x) => x.name)

      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    deconstructTriggerCondition() {
      const tempTriggerCondition = this.cron.triggerCondition ? this.cron.triggerCondition.split(' ') : null

      if (tempTriggerCondition) {

        const tempSeconds = tempTriggerCondition[0].split('/')

        this.seconds = tempSeconds[0] === '*' ? '*' : Number(tempSeconds[0])
        this.secondsInterval = tempSeconds.length > 1 ? Number(tempSeconds[1]) : null

        const tempMinutes = tempTriggerCondition[1].split('/')

        this.minutes = tempMinutes[0] === '*' ? '*' : Number(tempMinutes[0])
        this.minutesInterval = tempMinutes.length > 1 ? Number(tempMinutes[1]) : null

        const tempHours = tempTriggerCondition[2].split('/')

        this.hours = tempHours[0] === '*' ? '*' : Number(tempHours[0])
        this.hoursInterval = tempHours.length > 1 ? Number(tempHours[1]) : null

        this.dayOfMonth = tempTriggerCondition[3] === '*' ? '*' : Number(tempTriggerCondition[3])

        this.month = tempTriggerCondition[4] === '*' ? '*' : Number(tempTriggerCondition[4])

        this.dayOfWeek = tempTriggerCondition[5] === '*' ? '*' : Number(tempTriggerCondition[5])
      }
    },
    constructTriggerCondition() {
      return `${this.seconds}${this.secondsInterval ? `/${this.secondsInterval}` : ''} ${this.minutes}${this.minutesInterval ? `/${this.minutesInterval}` : ''} ${this.hours}${this.hoursInterval ? `/${this.hoursInterval}` : ''} ${this.dayOfMonth} ${this.month} ${this.dayOfWeek}`
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        page: 1,
        size: 10000,
        name: val || '',
        isSystem: false
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false

          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    copyEventId() {
      copy(this.successEventId.eventId)
    },
    goToEventLogs() {
      const routeData = this.$router.resolve({
        name: 'logs-events',
        query: {
          eventId: this.successEventId.eventId,
          processId: this.successEventId.processId,
          dateFrom: this.successEventId.dateFrom,
          dateTill: this.successEventId.dateTill,
          triggerId: this.$route.params.id
        }
      })

      window.open(routeData.href, '_blank')
    },
    goToStepLogs() {
      const routeData = this.$router.resolve({
        name: 'logs-steps',
        query: {
          eventId: this.successEventId.eventId,
          processId: this.successEventId.processId,
          dateFrom: this.successEventId.dateFrom,
          dateTill: this.successEventId.dateTill
        }
      })

      window.open(routeData.href, '_blank')
    },
    goToProcessDebug() {
      const routeData = this.$router.resolve({
        name: 'processEdit',
        params: { id: this.successEventId.processId },
        query: {
          eventId: this.successEventId.eventId
        }
      })

      window.open(routeData.href, '_blank')
    },
    async execute() {
      this.lock = true

      this.successEventId = {
        eventId: '',
        processId: '',
        dateFrom: '',
        dateTill: ''
      }

      let res = null

      const { id, processId } = this.cron

      try {

        res = await executeCron({ id })

        if (res && res.status !== 200) {

          this.addSnackbar({
            message: this.$lang.errors.cronExecute,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        this.successExe = this.$lang.success.cronExecute
        this.successEventId = {
          eventId: res.data.data.eventId,
          processId: processId,
          dateFrom: this.$options.filters.formatDate(new Date),
          dateTill: this.$options.filters.formatDate(new Date)
        }
        setTimeout(() => this.successExe = '', 10000)
        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false

      }
    },
    //TODO maybe somehow DRY with same functions in other triggers
    async createProcessForTrigger() {

      if (this.cron.name === null || this.cron.name.match(/^ *$/) !== null) {

        this.addSnackbar({
          message: 'Name cannot be empty!',
          timeout: 5000,
          color: 'error'
        })
      }

      const processNameExists = await doesProcessNameExist(this.cron.name)

      this.lock = true
      this.loading = true

      const res = await createProcessForTriggerData(this.cron.name, processNameExists, this.cronRoles)

      if (res && res.status !== 200) {
        // this.err =
        //       res?.response?.data?.statusText ||
        //      (this.$lang.errors.processCreate)
        this.addSnackbar({
          message:  res?.response?.data?.statusText ||
          (this.$lang.errors.processCreate),
          timeout: 5000,
          color: 'error'
        })
        // setTimeout(() => (this.err = ''), 5000)
        this.lock = false
        this.loading = false

        return
      } else if (res && res.status) {
        const processId = res.data.data.id
        const processName = res.data.data.name

        const existingProcess = this.processes.find((process) => process.id === processId)

        if (!existingProcess) {
          this.processes.push({ id: processId, name: processName })
        }
        this.cron.processId = processId
        this.addSnackbar({
          message: this.$lang.success.processCreated,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false
        this.loading = false
        this.showCreateProcessDialog = false
        this.submit()
      }
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.addSnackbar({
          message: this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.lock = true

      let res = null

      const { id } = this.cron

      if (!this.isEdit) {
        delete this.cron.id
        this.cron.status = 'ACTIVE'
      }
      delete this.cron.createdOn
      delete this.cron.modifiedOn

      this.cron.roles = this.cronRoles

      this.cron.triggerCondition = this.constructTriggerCondition()

      try {

        res = this.isEdit ? await updateCron({
          id,
          body: this.cron
        }) : await createCron({ body: this.cron })

        if (res && res.status !== 200) {
          const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.cronUpdate : this.$lang.errors.cronCreate)

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.cronUpdated : this.$lang.success.cronCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false

        if (!this.isEdit) {
          this.cron = res.data.data
          this.isEdit = true
          this.$router.push({
            name: 'cronEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    async changeStatus() {
      this.lock = true
      try {
        const res = this.cron.status === 'ACTIVE' ? await disableCron({ id: this.cron.id }) : await enableCron({ id: this.cron.id })

        if (res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.cronStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false
        }
        const successMessage = this.cron.status !== 'ACTIVE' ? this.$lang.success.cronActivated : this.$lang.success.cronDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.cron.status = res.data.data.status

        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
</style>
